import {defineStore} from 'pinia'
import MYTOOL from '@/utils/myTool';
import Axs from '@/utils/axs';
//const address = "127.0.0.1"
const address = "https://www.jl-zhwl.com"
const minIOAddress = "https://minio.jl-zhwl.com"
// const address = "http://127.0.0.1:32081"
// const minIOAddress = "http://127.0.0.1:9050"

function loadToken()
{
    let token = localStorage.getItem("token");
    let curtime =  (new Date()).getTime()/1000;
    let time = localStorage.getItem("tokenExpire");
    if(curtime - time >= 3600*24 )
    {
        localStorage.setItem("token","");
        return "";
    }
    return token;
}

let storeFunc = defineStore("info",{
    state:()=>{
        return {
           //业务数据  count:99
           logoutFlash:false,
           token:loadToken(),
           smsWaittingSecond:0,
           defaultSvg:"<img style='width:90px;height:28px;' src='#'>",
           autoqrcode:"",
           reset_acc:"",
           reset_code:"",
           reset_sms:"",
           version:"0.0.0",
           AccountTypeEnum:[
            {
                id: 0,
                label: "普通账号"
            },
            {
                id: 1,
                label: "手机号"
            },
            {
                id: 2,
                label: "邮箱"
            },
            {
                id: 3,
                label: "微信"
            },
            {
                id: 4,
                label: "QQ"
            },
            {
                id: 5,
                label: "新浪微博"
            },
        ],
        }
    },
    actions:{
        //方法
        // doSum()
        // {
        //     this.count++
        // }
        saveToken(value)
        {
            localStorage.setItem("token",value);
            let curtime =  (new Date()).getTime()/1000;
            localStorage.setItem("tokenExpire",curtime);
            this.token = value;
        },

        loadToken()
        {
            let token = localStorage.getItem("token");
            let curtime =  (new Date()).getTime() / 1000;
            let time = localStorage.getItem("tokenExpire");
            if(curtime - time >= 3600*24 )
            {
                localStorage.setItem("token","");
                this.token ="";
                return "";
            }
            this.token = token;
            return token;
        },

         getQrCode() {
             console.log("11111");
            let nanoid = localStorage.getItem("nanoid");
            if (MYTOOL.isEmpty(nanoid)) {
              nanoid = "\\0";
            } else {
              nanoid = "\\" + nanoid;
            }
            
            Axs.get(this.linkUrl + "api/qrcode" + nanoid, null, null, (req) => {
              // console.log("req",req)
              if (!MYTOOL.isEmpty(req.nanoid)) {
                localStorage.setItem("nanoid", req.nanoid);
              }
              this.defaultSvg =
                "<div style='display:inline-block;vertical-align:top;font-size:0;'>" +
                req.data.code +
                "</div>";
              if (!MYTOOL.isEmpty(req.data.smsWaittingSecond))
              this.smsWaittingSecond = req.data.smsWaittingSecond;
      
              this.autoqrcode = req.data.autoqrcode
            },null,null,true);
          },
          clear()
          {
            this.token =loadToken();
            this.smsWaittingSecond=0;
            this.defaultSvg="<img style='width:90px;height:28px;' src='#'>";
            this.autoqrcode="";
            this.reset_acc="";
            this.reset_code="";
            this.reset_sms="";
          }
    },
    getters:{
        //计算属性，只读属性
        // linkAddress(){
        //     return address + ":"+ port
        // },
        // linkUrl(){
        //     return "http://" +  address + ":"+ port + "/"
        // },
        linkUrl(){
            return address + "/" 
        },
        // linkMinIOUrl()
        // {
        //     return "http://" +  address + ":"+ minIOport + "/"
        // }
        linkMinIOUrl()
        {
            return minIOAddress + "/" 
        }
    },
    persist: true
})

 //组合式写法 mixin混合写法
// let storeFunc = defineStore("user",()=>{
//     //组合式写法
//     let p = ref("xx");
//     return {
//         p
//     }
// })


export default storeFunc